.menu::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}
.menu::-webkit-scrollbar-track {
  display: none;
}

.menu::-webkit-scrollbar-thumb {
  display: none;
}

.menu::-webkit-scrollbar-button {
  display: none;
}

.menu:hover::-webkit-scrollbar-thumb {
  display: block;
  background-color: rgb(179, 179, 179);
  border-radius: 16px;
  border: 4px solid rgb(179, 179, 179);
}

.menu::-webkit-scrollbar-thumb:hover {
  background-color: #7e7e7e;
  border: 4px solid #7e7e7e;
}

@-moz-document url-prefix() {
  .menu {
    scroll-behavior: smooth;
    scrollbar-color: rgb(179, 179, 179) transparent;
    scrollbar-width: none;
    overflow-y: auto;
  }
  .menu:hover {
    scrollbar-width: thin;
  }
}

.dark .react-datepicker__header {
  background-color: var(--chakra-colors-gray-600);
  border-bottom-color: var(--chakra-colors-gray-600);
}
.dark .react-datepicker__current-month,
.dark .react-datepicker__day-name,
.dark .react-datepicker__day {
  color: var(--chakra-colors-gray-300);
}
.dark .react-datepicker__day--keyboard-selected {
  color: var(--chakra-colors-white);
}
/*pager arrow button*/
.dark .react-datepicker__navigation-icon::before {
  border-color: var(--chakra-colors-gray-500);
  margin-top: 6px;
}
.dark .react-datepicker__day:hover {
  background-color: var(--chakra-colors-gray-600);
}
.dark .react-datepicker__day--keyboard-selected {
  background-color: var(--chakra-colors-blue-200);
  color: var(--chakra-colors-gray-800);
}
.dark .react-datepicker__day--keyboard-selected:hover {
  background-color: var(--chakra-colors-blue-300);
}

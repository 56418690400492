.scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 8px;
}
.scrollbar::-webkit-scrollbar-track {
  display: none;
}

.scrollbar::-webkit-scrollbar-thumb {
  display: none;
}

.scrollbar::-webkit-scrollbar-button {
  display: none;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
  display: block;
  background-color: rgb(179, 179, 179);
  border-radius: 16px;
  border: 4px solid rgb(179, 179, 179);
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #7e7e7e;
  border: 4px solid #7e7e7e;
}

@-moz-document url-prefix() {
  .scrollbar {
    scroll-behavior: smooth;
    scrollbar-color: rgb(179, 179, 179) transparent;
    scrollbar-width: none;
    overflow-y: auto;
  }
  .scrollbar:hover {
    scrollbar-width: thin;
  }
  .no-style-textarea {
    overflow-y: hidden;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
  }
}

.reactGridItem .show-on-hover {
  visibility: hidden !important;
}

.reactGridItem:hover .show-on-hover {
  visibility: visible !important;
}

.reactGridItem .react-resizable-handle {
  visibility: hidden !important;
}

.reactGridItem:hover .react-resizable-handle {
  visibility: visible !important;
}

.reactGridItem .drag-me {
  cursor: grab;
}

.reactGridItem:active .drag-me {
  cursor: grabbing;
}

.no-style-textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  background-color: transparent;
  width: 100%;
}

.no-style-textarea::-webkit-scrollbar {
  display: none;
}

.view .delete-button {
  visibility: hidden !important;
}

.view:hover .delete-button {
  visibility: visible !important;
}

.animation {
  animation: fadeIn linear 0.5s;
}

.highlight-hint {
  background-color: rgb(255, 251, 0);
  font-weight: bold;
}
.highlight-extractor {
  background-color: rgb(117, 247, 208);
  font-weight: bold;
}
.highlight-mixed {
  background-color: rgb(192, 249, 95);
  font-weight: bold;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* left side menu */
.dark .menu-content {
  background-color: var(--chakra-colors-gray-700);
}
.dark .search-icon path, .dark .-depth1 svg * /*poligon*/ {
  fill: var(--chakra-colors-gray-200) !important;
}
.dark .search-input,
.dark label[role='menuitem'] {
  color: var(--chakra-colors-gray-200) !important;
}
.dark label[role='menuitem']:hover {
  background-color: var(--chakra-colors-gray-600) !important;
}
.dark label[role='menuitem'].active {
  background-color: var(--chakra-colors-gray-500);
}
.dark .operation-type.post {
  background-color: var(--chakra-colors-blue-500);
}
.dark .scrollbar-container div a {
  color: var(--chakra-colors-gray-300) !important;
}
.dark .menu-content div[role='search'] div {
  background-color: var(--chakra-colors-gray-500);
  color: var(--chakra-colors-gray-200);
}

/* main area */
.dark .redoc-wrap {
  background-color: inherit;
}
.dark .api-content p {
  color: var(--chakra-colors-gray-400);
}
.dark .api-content a {
  border-color: var(--chakra-colors-blue-200);
  color: var(--chakra-colors-blue-200);
}
.dark .api-content h1 {
  color: var(--chakra-colors-blue-400);
}
.dark .api-content h2 {
  color: var(--chakra-colors-gray-200);
}
.dark .api-content h5,
.dark .api-content h5 span {
  color: var(--chakra-colors-gray-400);
}
.dark .api-content td span {
  color: var(--chakra-colors-gray-400);
}
.dark .api-content td div {
  background-color: var(--chakra-colors-gray-900);
}
.dark .api-content td svg * {
  fill: var(--chakra-colors-gray-400);
}
.dark .api-content tr * {
  background-color: var(--chakra-colors-gray-900);
  border-color: var(--chakra-colors-gray-500);
  color: var(--chakra-colors-gray-400);
}

/* right area */
.dark .api-content + div {
  background-color: var(--chakra-colors-gray-300);
}
.dark .api-content div[data-section-id^='operation'] > div > div + div {
  background-color: var(--chakra-colors-gray-300);
  color: var(--chakra-colors-gray-900);
}
.dark .api-content div[data-section-id^='operation'] > div > div + div > div > button {
  background-color: var(--chakra-colors-gray-200);
}
.dark .api-content div[data-section-id^='operation'] > div > div + div > div > button > span:first-child {
  background-color: var(--chakra-colors-blue-500);
}
.dark .api-content div[data-section-id^='operation'] > div > div + div > div > button > svg * {
  fill: var(--chakra-colors-gray-900);
}
.dark .api-content div[data-section-id^='operation'] > div > div + div > div > div {
  background-color: var(--chakra-colors-gray-300);
}
.dark .api-content div[data-section-id^='operation'] > div > div + div > div > div > div > div + div > div {
  border-color: var(--chakra-colors-gray-200);
  background-color: var(--chakra-colors-gray-200);
}
.dark .api-content div[data-section-id^='operation'] > div > div + div h3 {
  color: var(--chakra-colors-gray-900);
}
.dark .api-content div[data-section-id^='operation'] > div > div + div > div > div > ul li {
  background-color: var(--chakra-colors-gray-400);
  color: var(--chakra-colors-gray-200);
}
.dark .api-content div[data-section-id^='operation'] li.react-tabs__tab--selected {
  background-color: var(--chakra-colors-gray-800);
  border-color: var(--chakra-colors-gray-200);
}
.dark .api-content div[data-section-id^='operation'] li.tab-success {
  color: var(--chakra-colors-green-500);
  border-color: var(--chakra-colors-green-500);
}
.dark .api-content div[data-section-id^='operation'] li.tab-error {
  color: var(--chakra-colors-red-500);
  border-color: var(--chakra-colors-red-500);
}
.dark .react-tabs__tab-panel > div {
  background-color: var(--chakra-colors-gray-200);
}
.dark .api-content div[data-section-id^='operation'] > div > div + div ul.collapsible li {
  background-color: var(--chakra-colors-gray-200);
}
.dark .react-tabs__tab-panel .hoverable {
  color: var(--chakra-colors-gray-800);
}
.dark .react-tabs__tab-panel .property.token.string {
  color: var(--chakra-colors-gray-800);
}
.dark .react-tabs__tab-panel .collapser {
  color: var(--chakra-colors-gray-800);
}
.dark .react-tabs__tab-panel .token.string {
  color: var(--chakra-colors-green-700);
}
.dark .react-tabs__tab-panel .token.number {
  color: var(--chakra-colors-blue-700);
}
.dark .react-tabs__tab-panel .token.boolean {
  color: var(--chakra-colors-yellow-700);
}
.dark .react-tabs__tab-panel > div > div:first-child > div {
  background-color: var(--chakra-colors-gray-100);
  color: var(--chakra-colors-gray-900);
}
.dark .react-tabs__tab-panel > div > div:first-child span {
  color: var(--chakra-colors-gray-800);
}

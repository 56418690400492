/* left side menu */
.light .menu-content {
  background-color: var(--chakra-colors-gray-100);
}
.light .search-icon path, .light .-depth1 svg * /*poligon*/ {
  fill: var(--chakra-colors-gray-800) !important;
}
.light .search-input,
.light label[role='menuitem'] {
  color: var(--chakra-colors-gray-800) !important;
}
.light label[role='menuitem']:hover {
  background-color: var(--chakra-colors-gray-300) !important;
}
.light label[role='menuitem'].active {
  background-color: var(--chakra-colors-gray-400);
}
.light .operation-type.post {
  background-color: var(--chakra-colors-blue-500);
}
.light .scrollbar-container div a {
  color: var(--chakra-colors-gray-600) !important;
}
.light .menu-content div[role='search'] div {
  background-color: var(--chakra-colors-gray-200);
  color: 'inherit';
}

/* main area */
.light .redoc-wrap {
  background-color: inherit;
}
.light .api-content p {
  color: inherit;
}
.light .api-content a {
  border-color: var(--chakra-colors-blue-500);
  color: var(--chakra-colors-blue-500);
}
.light .api-content h1 {
  color: var(--chakra-colors-blue-700);
}
.light .api-content h2 {
  color: var(--chakra-colors-gray-700);
}
.light .api-content h5,
.light .api-content h5 span {
  color: var(--chakra-colors-gray-700);
}
.light .api-content td span {
  color: var(--chakra-colors-gray-500);
}
.light .api-content td div {
  background-color: inherit;
}
.light .api-content td svg * {
  fill: var(--chakra-colors-gray-600);
}
.light .api-content tr * {
  background-color: inherit;
  border-color: var(--chakra-colors-gray-600);
  color: var(--chakra-colors-gray-700);
}

/* right area */
.light .api-content + div {
  background-color: var(--chakra-colors-gray-600);
}
.light .api-content div[data-section-id^='operation'] > div > div + div {
  background-color: var(--chakra-colors-gray-600);
  color: var(--chakra-colors-gray-100);
}
.light .api-content div[data-section-id^='operation'] > div > div + div > div > button {
  background-color: var(--chakra-colors-gray-800);
}
.light .api-content div[data-section-id^='operation'] > div > div + div > div > button > span:first-child {
  background-color: var(--chakra-colors-blue-500);
}
.light .api-content div[data-section-id^='operation'] > div > div + div > div > button > svg * {
  fill: var(--chakra-colors-gray-100);
}
.light .api-content div[data-section-id^='operation'] > div > div + div > div > div {
  background-color: var(--chakra-colors-gray-600);
}
.light .api-content div[data-section-id^='operation'] > div > div + div > div > div > div > div + div > div {
  border-color: var(--chakra-colors-gray-800);
  background-color: var(--chakra-colors-gray-800);
}
.light .api-content div[data-section-id^='operation'] > div > div + div h3 {
  color: var(--chakra-colors-gray-100);
  border-color: var(--chakra-colors-gray-800);
}
.light .api-content div[data-section-id^='operation'] > div > div + div > div > div > ul li {
  background-color: var(--chakra-colors-gray-500);
  color: var(--chakra-colors-gray-800);
}
.light .api-content div[data-section-id^='operation'] li.react-tabs__tab--selected {
  background-color: var(--chakra-colors-gray-200);
}
.light .api-content div[data-section-id^='operation'] li.tab-success {
  color: var(--chakra-colors-green-500);
  border-color: var(--chakra-colors-green-500);
}
.light .api-content div[data-section-id^='operation'] li.tab-error {
  color: var(--chakra-colors-red-600);
  border-color: var(--chakra-colors-red-600);
}
.light .react-tabs__tab-panel > div {
  background-color: var(--chakra-colors-gray-800);
}
.light .api-content div[data-section-id^='operation'] > div > div + div ul.collapsible li {
  background-color: var(--chakra-colors-gray-800);
}
.light .react-tabs__tab-panel .hoverable {
  color: var(--chakra-colors-gray-200);
}
.light .react-tabs__tab-panel .property.token.string {
  color: var(--chakra-colors-gray-200);
}
.light .react-tabs__tab-panel .collapser {
  color: var(--chakra-colors-gray-200);
}
.light .react-tabs__tab-panel .token.string {
  color: var(--chakra-colors-green-200);
}
.light .react-tabs__tab-panel .token.number {
  color: var(--chakra-colors-blue-200);
}
.light .react-tabs__tab-panel .token.boolean {
  color: var(--chakra-colors-yellow-200);
}
.light .react-tabs__tab-panel > div > div:first-child > div {
  background-color: var(--chakra-colors-gray-900);
  color: var(--chakra-colors-gray-100);
}
.light .react-tabs__tab-panel > div > div:first-child span {
  color: var(--chakra-colors-gray-200);
}
